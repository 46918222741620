import React, { Component } from 'react'
import avatarImg from '../../assets/images/shouvik-mukherjee.jpg'

class Avatar extends Component {
  render () {
    return (
      <img alt='Shouvik Mukherjee' src={avatarImg} className='rounded-full m-auto h-64 w-64 border-grey border-8' />
    )
  }
}

export default Avatar
