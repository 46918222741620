import React, { Component } from 'react'

class Stack extends Component {
  render () {
    return (
      <div className='flex flex-wrap'>
        <div className='w-full lg:w-1/3 lg:pr-1 pb-4'>
          <h3 className='mb-3 pb-3 border-b-2 border-gray-700 uppercase text-l'>Backened</h3>
          <ul className='list-disc list-inside pl-2'>
            <li>Ruby on Rails</li>
            <li>ExpressJS</li>
            <li>MySQL</li>
            <li>PostgreSQL</li>
            <li>MongoDB</li>
            <li>Redis</li>
            <li>Memcached</li>
          </ul>
        </div>

        <div className='w-full lg:w-1/3 lg:px-2 pb-4'>
          <h3 className='mb-3 pb-3 border-b-2 border-gray-700 uppercase text-l'>Frontend</h3>
          <ul className='list-disc list-inside pl-2'>
            <li>ReactJS (SSR)</li>
            <li>React Native</li>
            <li>TailWind CSS</li>
            <li>Bootstrap</li>
            <li>Material UI</li>
            <li>Semantic UI</li>
            <li>Ant UI</li>
          </ul>
        </div>

        <div className='w-full lg:w-1/3 lg:pl-1 pb-4'>
          <h3 className='mb-3 pb-3 border-b-2 border-gray-700 uppercase text-l'>Misc</h3>
          <ul className='list-disc list-inside pl-2'>
            <li>Docker</li>
            <li>Serverless (Lambda)</li>
            <li>Firebase</li>
            <li>Amazon Web Services</li>
            <li>Google Cloud</li>
          </ul>
        </div>
      </div>
    )
  }
}

export default Stack
