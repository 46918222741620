import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faYoutube,
  faLinkedin,
  faGithub,
  faTwitter,
  faInstagram
} from '@fortawesome/free-brands-svg-icons'

class SocialLinks extends Component {
  render () {
    return (
      <>
        <a
          className='bg-red-600 hover:bg-gray-600 text-white font-bold py-3 px-4 my-3 mr-2 border-b-4 border-red-700 hover:border-gray-800 rounded block lg:inline'
          href='https://youtube.com/ishouvik'
          target='blank'
          title='Follow @iShouvik'
        >
          <FontAwesomeIcon icon={faYoutube} className='mr-2' />
          YouTube
        </a>
        <a
          className='bg-blue-700 hover:bg-gray-600 text-white font-bold py-3 px-4 my-3 mr-2 border-b-4 border-blue-900 hover:border-gray-800 rounded block lg:inline'
          href='https://www.linkedin.com/in/shouvikmukherjee'
          target='blank'
          title='Follow @iShouvik'
        >
          <FontAwesomeIcon icon={faLinkedin} className='mr-2' />
          LinkedIn
        </a>
        <a
          className='bg-gray-800 hover:bg-gray-600 text-white font-bold py-3 px-4 my-3 mr-2 border-b-4 border-black hover:border-gray-800 rounded block lg:inline'
          href='https://github.com/ishouvik'
          target='blank'
          title='Follow @iShouvik'
        >
          <FontAwesomeIcon icon={faGithub} className='mr-2' />
          Github
        </a>
        <a
          className='bg-blue-500 hover:bg-gray-600 text-white font-bold py-3 px-4 my-3 mr-2 border-b-4 border-blue-700 hover:border-gray-800 rounded block lg:inline'
          href='https://twitter.com/ishouvik'
          target='blank'
          title='Follow @iShouvik'
        >
          <FontAwesomeIcon icon={faTwitter} className='mr-2' />
          Twitter
        </a>
        <a
          className='bg-orange-800 hover:bg-gray-600 text-white font-bold py-3 px-4 my-3 border-b-4 border-orange-900 hover:border-gray-800 rounded block lg:inline'
          href='https://www.instagram.com/ishouvik'
          target='blank'
          title='Follow @iShouvik'
        >
          <FontAwesomeIcon icon={faInstagram} className='mr-2' />
          Instagram
        </a>
      </>
    )
  }
}

export default SocialLinks
