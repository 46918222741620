import React, { Component } from 'react'
import Avatar from '../../components/home/avatar'
import AboutMe from '../../components/home/about-me'
import SocialLinks from '../../components/home/social-links'
import Stack from '../../components/home/stack'

class HomePage extends Component {
  render () {
    return (
      <>
        <div className='container mx-auto min-h-1/3 p-4 py-20 bg-white'>
          <div className='flex flex-wrap'>
            <div className='sm:w-full md:w-full lg:w-1/3 text-center'>
              <Avatar />
            </div>
            <div className='sm:w-full md:w-full lg:w-2/3'>
              <div className='mb-10'>
                <AboutMe />
              </div>
              <div className='border-t-2 w-1/3 m-auto mb-10' />
              <div className='mt-6'>
                <SocialLinks />
              </div>
            </div>
          </div>
        </div>
        <div className='bg-blue-900 py-12 px-4 min-h-2/3'>
          <div className='container mx-auto text-gray-400 font-thin'>
            <Stack />
          </div>
        </div>
      </>
    )
  }
}

export default HomePage
