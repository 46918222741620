import React, { Component } from 'react'

class AboutMe extends Component {
  render () {
    return (
      <>
        <h3 className='text-gray-700 uppercase'>my name is</h3>
        <h1 className='text-5xl mb-3 font-thin text-gray-700 uppercase'>
          <span className='text-blue-600'>&#123;</span> Shouvik Mukherjee{' '}
          <span className='text-blue-600'>&#125;</span>
        </h1>
        <h2 className='text-xl mb-3 font-light uppercase'>
          <span className='text-blue-600'>E</span>ntrepreneur |{' '}
          <span className='text-blue-600'>F</span>ull-Stack Developer |{' '}
          <span className='text-blue-600'>P</span>roduct Enthusiast
        </h2>
        <div className='text-xl text-gray-700 font-thin'>
          <p className='mt-10'>
            I am a web developer from{' '}
            <span className='text-blue-600 font-normal'>Bangalore, India</span>.
            I have been working with web tech for about 10 years. I started out
            as a freelance developer and OpenSource contributor to the elgg.org
            platform. After that for some time I worked on Php CodeIgnier
            followed by my switch to Ruby On Rails.
          </p>
          <p className='my-4'>
            These days I work with a startup called{' '}
            <span className='text-blue-600 font-normal'>Bang the Table</span>{' '}
            and find my day-to-day comfort in{' '}
            <span className='text-blue-600 font-normal'>ExpressJS</span>,{' '}
            <span className='text-blue-600 font-normal'>ReactJS</span> on{' '}
            <span className='text-blue-600 font-normal'>Serverless</span> and{' '}
            <span className='text-blue-600 font-normal'>Docker</span> based{' '}
            infrastructure.
          </p>
        </div>
      </>
    )
  }
}

export default AboutMe
